import React from "react";
import RewindHome from './Music-Analyzer/App'

function Music() {
    return (
        <>
            <div className='w-full p-5'>
                <RewindHome/>
            </div>
        </>
    )
}

export default Music;